<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12" lg="10">
        <v-card
          elevation="15"
          class="rounded-lg align-center justify-center"
          style="overflow: hidden; background: #000;"
        >
          <div style="padding:56.25% 0 0 0;position:relative;">
            <iframe
              :src="stream"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
              style="position:absolute;top:0;left:0;width:100%;height:100%;"
            ></iframe>
          </div>
        </v-card>
        <!--
        <v-row class="mt-5">
          <v-col class="d-flex flex-row justify-center align-center">
            <div class="flex flex-grow text-right white--text text-bold mr-4">
              Stream Language:
            </div>
            <div class="flex flex-grow-0">
              <v-select
                class="flex"
                :items="languages"
                solo
                dense
                prepend-inner-icon="mdi-translate"
                :value="$i18n.locale"
                hide-details
                @input="setLang"
              />
            </div>
          </v-col>
        </v-row>
        -->

        <v-toolbar
          dark
          flat
          color="transparent"
          :dense="$vuetify.breakpoint.xsOnly"
        >
          <v-chip v-if="visitorCount"
            ><v-icon left>mdi-eye</v-icon>{{ formattedCount }}</v-chip
          >
          <v-spacer v-if="$vuetify.breakpoint.smAndUp" />

          <v-btn-toggle
            v-model="selectedLangIndex"
            group
            :dense="$vuetify.breakpoint.xsOnly"
            mandatory
          >
            <v-btn
              v-for="source in languages"
              :key="source.text"
              :small="$vuetify.breakpoint.xsOnly"
            >
              {{ source.text }}
            </v-btn>
          </v-btn-toggle>
        </v-toolbar>
      </v-col>
    </v-row>
    <v-dialog :value="welcomeDialog" width="500">
      <v-card>
        <v-card-text class="pt-5 text-h6 text-center">
          {{ $t('welcome.popup') }}
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            depressed
            class="ma-2"
            @click="welcomeDialog = false"
          >
            {{ $t('welcome.proceed') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<route>
{}
</route>

<script>
//import Sidebar from '@/components/sidebar/Sidebar';
// import IVSPlayer from '@/components/IVSPlayer';
// import Plyr from '../../components/Plyr.vue';

export default {
  name: 'Stage',
  components: {},
  head() {
    return {
      title: this.title[this.$route.params.stage]
    };
  },
  data() {
    return {
      welcomeDialog: false,
      waitMode: false,
      timeout: null,
      selectedLangIndex: 0,
      plyrOptions: {
        playsinline: true,
        controls: ['play-large', 'play', 'mute', 'volume', 'fullscreen'],
        ratio: '16:9'
      },
      title: {
        main: 'Ana Salon'
      },
      languages: [
        { text: 'English', value: 'en' },
        { text: 'Turkish', value: 'tr' },
        { text: 'Chinese', value: 'zh' }
      ],
      streams: {
        main: {
          en:
            'https://player.vimeo.com/video/638654738?h=150f9d5c00&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
          tr:
            'https://player.vimeo.com/video/640906325?h=6b855020f0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
          zh:
            'https://player.vimeo.com/video/640905086?h=409460e4e5&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
        }
      }
    };
  },
  computed: {
    usr() {
      return this.$store.state.auth?.user;
    },
    selectedLang() {
      return this.languages[this.selectedLangIndex].value;
    },
    stream() {
      if (this.streams[this.$route.params.stage]) {
        return (
          this.streams[this.$route.params.stage][this.selectedLang] ||
          Object.values(this.streams[this.$route.params.stage])[0]
        );
      }
      return null;
    }
  },
  methods: {
    setLang(lng) {
      this.selectedLang = lng;
    }
  },
  created() {
    if (!Object.keys(this.streams).includes(this.$route.params.stage)) {
      this.$router.push(`/stage/${Object.keys(this.streams)[0]}`);
      return;
    }
    if (this.waitMode) {
      this.timeout = setTimeout(() => {
        window.location.reload();
      }, 1000 * 60 * 3);
    }
  },
  beforeDestroy() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }
};
</script>

<style></style>
